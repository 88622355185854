<script setup lang="ts">
import { PhoneIcon, SmsTrackingIcon } from '@cfgtech/icons'
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { EMAIL } from '~/constants/contact'
import type { SbContactSectionStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbContactSectionStoryblok }>()

const openingHours = useOpeningHours()

const { t } = useI18n()
const { openCallpageDialog } = useCallpageTracking()

const renderedTitle = computed(() => blok.title?.content?.length ? renderRichText(blok.title) : null)
const renderedTitleSize = computed(() => blok.titleSize || 'h1')

type ContactCard = {
  title: string
  subtitle: string
  icon?: Component
  cta: string
  id: string
  action: () => void
}

const contactCards = computed<ContactCard[]>(() => {
  return [
    {
      title: t('contact.callBack.title'),
      subtitle: openingHours ? t('contact.callBack.subtitle') : t('contact.chooseDate.subtitle'),
      cta: openingHours ? t('contact.callBack.button') : t('contact.chooseDate.button'),
      icon: PhoneIcon,
      id: openingHours ? 'call-back' : 'schedule-call',
      action: async () => {
        openCallpageDialog()
        if (openingHours)
          return

        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
        const $schedule = document.querySelector<HTMLElement>('[data-contact-type="schedule"]')
        $schedule?.click()
      },
    },
    {
      title: t('contact.writeMessage.title'),
      subtitle: openingHours ? t('contact.writeMessage.subtitle') : t('contact.writeMessage.subtitleOffline'),
      cta: t('contact.writeMessage.button'),
      icon: SmsTrackingIcon,
      id: 'write-message',
      action: () => {
        window.open(`mailto:${EMAIL}?subject=${encodeURIComponent(t('contact.writeMessage.subject'))}`)
      },
    },
  ]
})
</script>

<template>
  <div v-editable="blok" class="relative isolate">
    <div
      class="absolute inset-0 left-1/2 -z-10 w-screen -translate-x-1/2 bg-brand-dark"
    />

    <div class="w-full py-[90px] lg:py-[120px]">
      <!-- Online status -->
      <div class="mb-4 flex items-center justify-center gap-2">
        <template v-if="openingHours">
          <span class="size-2 animate-online rounded-full bg-green" />

          <CfgTypography
            class="!font-semibold text-white"
            :size="CfgTypographySizes.sm"
          >
            {{ $t('contact.onlineStatus') }}
          </CfgTypography>
        </template>

        <div class="ml-2 flex -space-x-2">
          <img
            v-for="{ filename, alt, id: key } in blok.sales"
            :key="key"
            :alt="alt"
            class="size-[42px] rounded-full border-2 border-brand-dark bg-white lg:size-[52px]"
            :src="filename"
          >
        </div>
      </div>

      <!-- Heading -->
      <div class="mx-auto max-w-[460px]">
        <TypographyStyledText v-if="renderedTitle" class="text-center">
          <TypographySanitizedHTML
            v-if="renderedTitle"
            :allowed-classes="['text-brand', 'text-white']"
            :allowed-tags="['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'b', 'strong', 'br', 'a']"
            :html="renderedTitle"
          >
            <template #default="{ html }">
              <CfgTypography
                class="text-balance"
                :class="{
                  'text-[28px] md:text-[56px]': renderedTitleSize === 'h1-large',
                }"
                :html="html"
                :size="CfgTypographySizes[renderedTitleSize]"
                tag-name="span"
              />
            </template>
          </TypographySanitizedHTML>
        </TypographyStyledText>
      </div>

      <!-- Cards -->
      <div class="mt-10 grid grid-cols-12 gap-5 md:mt-12 md:gap-8">
        <div class="col-span-2 max-lg:hidden" />

        <AppCard
          v-for="card in contactCards"
          :id="card.id"
          :key="card.id"
          class="col-span-12 flex cursor-pointer items-start gap-4 !p-5 transition-transform duration-[250ms] md:col-span-6 md:hover:-translate-y-2 lg:col-span-4"
          @click="card.action"
        >
          <div
            v-if="card.icon"
            class="flex aspect-square size-[45px] shrink-0 items-center justify-center rounded-full bg-orange text-white"
          >
            <component :is="card.icon" class="text-[24px]" />
          </div>

          <div class="flex h-full flex-col items-start justify-start">
            <CfgTypography
              class="!font-nunito !font-extrabold"
              :size="CfgTypographySizes.subtitle"
              tag-name="h3"
            >
              {{ card.title }}
            </CfgTypography>

            <CfgTypography
              class="mt-1"
              :size="CfgTypographySizes.sm"
            >
              {{ card.subtitle }}
            </CfgTypography>

            <div class="grow" />

            <CfgButton
              class="mt-4 justify-self-end rounded-full px-2 py-1"
              small
              variant="orange-light"
            >
              {{ card.cta }}
            </CfgButton>
          </div>
        </AppCard>
      </div>
    </div>
  </div>
</template>
